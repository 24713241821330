import { Outlet } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import Footer from './footer';
import Header from './header';
import { useTheme } from '@mui/material/styles';
import { createContext, useEffect, useState } from 'react';
import { path } from 'config/path';

export const StoreContext = createContext(null);

const HomeLayout = () => {
  const theme = useTheme();
  const [cart, setCart] = useState(localStorage.getItem("choice-cart") ? JSON.parse(localStorage.getItem("choice-cart")) : []);
  const [allmenu, setAllmenu] = useState([]);
  const [store, setStore] = useState({});

  useEffect(() => {
    async function getMenu() {
      let res = await fetch(`${path.ciHost}/index.php/api/v1/store/` + process.env.REACT_APP_STOREID);
			const store = await res.json();
      //console.log(store);
      setStore(store);
      res = await fetch(`${path.ciHost}/index.php/api/v1/product/`+ store.id);
      const products = await res.json();
      //console.log(products);
      const categories = new Map();
      products.map((product) => {
        if (!categories.get(product.category)) {
          categories.set(product.category, new Map());
        }
        const subcat = categories.get(product.category);
        if (!subcat.get(product.subcategory)) {
          subcat.set(product.subcategory, []);
        }
        subcat.get(product.subcategory).push(product);
      });
      //console.log(categories);
      setAllmenu(categories);
    }
    getMenu();
  }, []);

  useEffect(() => {
    localStorage.setItem("choice-cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <StoreContext.Provider value = {{ store, allmenu, cart, setCart }}>
      <Box id='home-layout' width='100%' sx={{ fontSize: {xs:'9px', sm:'10px'} }}>            
        <Header />
        <Container maxWidth="lg" sx={{ py: 0, mt: {xs:'7.2em', md:'10em'}, px:{xs:0, sm:0, md:5} }}>
          <Outlet />
        </Container>
        <Footer/>
      </Box>
    </StoreContext.Provider>
  )
};

export default HomeLayout;